<template>
    <div class="container">
        <div class="login-wrapper fadeInDown">
            <div class="login-content padding-20">
                <!-- Tabs Titles -->

                <!-- Icon -->
                <div class="fadeIn first">
                    <img class="login-logo" src="../assets/logo_rivieranuragica.jpg" alt="Riviera Icon" />
                </div>

                <!-- Login Form -->
                <form
                        id="form-login"
                        @submit.prevent="loginWithEmailPassword(email, password)"
                >
                    <div
                            v-show="errorMessage"
                            class="alert alert-danger"
                            id="modal-error-message"
                            role="alert"
                    >
                        {{ errorMessage }}
                    </div>
                    <p>
                    <input
                            type="text"
                            id="email"
                            class="form-control"
                            name="email"
                            :placeholder="$t('common.email')"
                            v-model="email"
                            required
                    />
                    </p>
                    <p>
                    <input
                            type="password"
                            id="password"
                            class="form-control"
                            name="password"
                            :placeholder="$t('common.password')"
                            v-model="password"
                            required
                    />
                    </p>
                    <input type="submit" class="btn btn-primary btn-lg btn-block" value="Accedi" />
                </form>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </div>
</template>

<script>
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import { doLoginUser, getUserInfo } from "../api";
    import { StorageHelper } from "../common/storageHelper";
    import {Utils} from "../common/utils";

    export default {
        title: 'Login',
        name: "Login",
        components: { SpinnerLoader },
        data: function() {
            return {
                loading: false,
                email: null,
                password: null,
                errorMessage: ""
            };
        },
        methods: {
            async loginWithEmailPassword(username, password) {
                this.loading = true;
                this.errorMessage = "";
                try {
                    const responseLogin = await doLoginUser(username, password);
                    StorageHelper.setToken(responseLogin.token);
                    /** User info got by token saved in storage helper*/
                    const responseUser = await getUserInfo();
                    StorageHelper.setUser(responseUser);
                    /** User must be handler for use manage section */
                    this.loading = false;

                    this.$router.push({ name: Utils.getRedirectPageNameByUser(responseUser) });
                } catch (e) {
                    this.loading = false;
                    this.errorMessage = "Le credenziali immesse non sono corrette";

                }
            }
        }
    };
</script>
